<div class="flex justify-center gap-2">
  @for (button of buttons; track button.id) {
  <div class="relative flex justify-center items-center">
    @if (button.isLoading) {
    <app-loading-indicator size="small" />
    }
    <a [id]="button.htmlId"
       class="flex justify-center items-center rounded-full h-10 w-10 leading-10"
       [class.hidden]="button.isLoading || (state == 'LINK' && button.isLinked)"
       [ngClass]="{
              'bg-[#1877F2]': button.type.startsWith('facebook'),
              'border border-gray-100': button.type == 'google',
              'bg-black': button.type.startsWith('apple'),
            }"
       type="submit"
       [title]="button.title"
       (click)="login(button.type)">
      <img [src]="button.logo"
           class="block" />
    </a>
  </div>
  }
</div>

@if (state === 'LINK') {
@for (button of buttons; track button.id) {
<div class="relative">
  @if (state === 'LINK' && button.isLinked) {
  <a (click)="unlink(button)"
     class="block text-center mt-2"> Unlink your {{ button.type | titlecase }} account </a>
  }
  @if (!!button.restrictions) {
  <div class="text-red">
    {{ button.restrictions }}
  </div>
  }
</div>
}
}

@if (errorMessage) {
<div class="text-red">
  {{ errorMessage }}
</div>
}