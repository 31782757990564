import { Observable } from 'rxjs';
import { Page } from '@aa/models/page';
import { HttpParams } from '@angular/common/http';

export type Pageable<T> = {
  page(page: number, options: PageableOptions): Observable<Page<T>>;
};

export type PageableOptions = {
  page?: number;
  letter?: string;
  pageSize?: number;
  offset?: number;
};

export function pageableOptionsToHttpParams(
  options: PageableOptions = {}
): HttpParams {
  const params: { [key: string]: string } = {};

  if (options.page) {
    params['page'] = `${options.page}`;
  }
  if (options.letter) {
    params['letter'] = options.letter;
  }
  if (options.pageSize) {
    params['per-page'] = `${options.pageSize}`;
  }
  if (options.offset) {
    params['offset'] = `${options.offset}`;
  }

  return new HttpParams({ fromObject: params });
}

