import { Component, Input, OnChanges, SimpleChanges, Inject, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Feature as OpenLayerFeature, Overlay } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import Point from 'ol/geom/Point';
import { EmptyMapComponent, MapLayer, MapOverlay } from '../empty-map/empty-map.component';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Cluster } from 'ol/source';
import { Style, Icon, Text, Fill } from 'ol/style';
import { ChangeDetectionStrategy } from '@angular/core';
import { FeatureLike } from 'ol/Feature';

const DECODER = new GeoJSON({
  featureProjection: 'EPSG:3857'
});

@Component({
  selector: 'app-map-trip-logs',
  templateUrl: './map-trip-logs.component.html',
  styleUrls: ['./map-trip-logs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapTripLogsComponent implements AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('overlayElement') element: ElementRef;

  @Input() tripLogs: any; // geojson
  @Input() cluster = true;

  public selectedTripLog: {
    place_name: string;
    last_date: string;
    hours_out: number;
    total_caught: number;
    catch_rate: number;
    routerLink: string[];
    queryParams: { [k: string]: any };
    buttonText: string;
  };

  private overlay: Overlay;
  private onClick: (feature: OpenLayerFeature) => void;
  private isReady = false;

  constructor(
    @Inject(EmptyMapComponent) private parent: EmptyMapComponent,
    private cdr: ChangeDetectorRef,
  ) { }

  ngAfterViewInit() {
    this.overlay = new Overlay({
      id: MapOverlay.TripLogs,
      element: this.element.nativeElement,
      autoPan: true,
    });

    this.onClick = (feature: OpenLayerFeature) => {
      if (!feature) { return }

      const onlyFeature = feature.get('features')[0];
      let buttonText: string;
      let routerLink: string[];
      let queryParams: { [k: string]: any };
      if (onlyFeature.get('total') > 1) {
        routerLink = ['/member/trips'];
        queryParams = {
          place_id: onlyFeature.get('place_id'),
          label: onlyFeature.get('place_name')
        };
        buttonText = 'View All Trips';
      } else {
        routerLink = ['/member/trips/' + onlyFeature.get('ids')[0]];
        queryParams = null;
        buttonText = 'View Trip';
      }

      this.selectedTripLog = {
        place_name: onlyFeature.get('place_name'),
        last_date: onlyFeature.get('last_date'),
        hours_out: onlyFeature.get('hours_out'),
        total_caught: onlyFeature.get('total_caught'),
        catch_rate: onlyFeature.get('catch_rate'),
        routerLink: routerLink,
        queryParams: queryParams,
        buttonText: buttonText
      };
      this.cdr.detectChanges();
      this.overlay.setPosition(
        (<Point>onlyFeature.getGeometry()).getCoordinates()
      );
    }

    const clusterSource = new Cluster({
      distance: this.cluster ? 80 : 0,
      source: new VectorSource()
    });

    const styleCache: { [key: number]: Style } = {};
    const layer = new VectorLayer({
      source: clusterSource,
      style: function(feature) {
        const clusteredFeatures = feature.get('features');
        const size = clusteredFeatures.reduce(
          (acc: number, elm: OpenLayerFeature) => {
            return acc + elm.get('total');
          },
          0
        );
        let style = styleCache[size];
        if (!style) {
          if (clusteredFeatures.length === 1) {
            style = new Style({
              image: new Icon({
                anchor: [0.5, 1.0],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                size: [30, 39],
                src: '/assets/markers/trip-log.svg'
              }),
              text: new Text({
                text: size.toString(),
                offsetY: -21,
                font: '16px sans-serif',
                fill: new Fill({
                  color: '#fff'
                })
              })
            });
          } else {
            style = new Style({
              image: new Icon({
                size: [66, 66],
                src: '/assets/markers/trip-log-cluster.svg'
              }),
              text: new Text({
                text: size.toString(),
                font: '18px sans-serif',
                fill: new Fill({
                  color: '#fff'
                })
              })
            });
          }
          styleCache[size] = style;
        }
        return style;
      }
    });
    this.parent.addLayer({
      key: MapLayer.TripLogs,
      layer: layer,
      onClick: this.onClick,
      deselectOnClick: true,
      onDeselect: () => {
        this.overlay.setPosition(undefined);
      }
    });
    this.parent.addOverlay(this.overlay);

    this.isReady = true;
    this.loadTripLogs();
  }

  ngOnChanges(_: SimpleChanges) {
    if (this.isReady) {
      this.loadTripLogs();
    }
  }

  ngOnDestroy() {
    this.parent.removeLayer(MapLayer.TripLogs);
    this.parent.removeOverlay(MapOverlay.TripLogs);
  }

  private loadTripLogs() {
    this.parent.getLayer(MapLayer.TripLogs).subscribe((layer) => {
      const features: OpenLayerFeature[] = [];
      if (this.tripLogs) {
        const decoded = DECODER.readFeatures(this.tripLogs);
        for (const it of decoded) {
          if (it instanceof OpenLayerFeature) {
            const slug = it.get('place_slug');
            const id = `trip-logs-${slug}`;
            it.setId(id);
            features.push(it);
          }
        }
      }

      const source = (<Cluster<FeatureLike>>layer.getSource()).getSource();
      source.clear();
      source.addFeatures(features);

      this.parent.fitExtent();
    });
  }

  clearSelected() {
    this.overlay.setPosition(undefined);
    this.parent.clearSelection();
  }
}
