import { PartialExcept, clone, cloneArray } from '@aa/helpers/objects';
import { Tournament } from './tournament';

export type BumpBoard = {
  id: number;
  created_at: string;
  url: string;
};

export type User = {
  id: number;
  username: string;
  created_at: string;
  updated_at?: string;
  first_name?: string;
  last_name?: string;
  display_name?: string;
  description?: string;
  email?: string;
  avatar_url: string;
  following_count: number;
  follower_count: number;
  place_count: number;
  location?: string;
  searchable?: boolean;
  accepted_terms?: boolean;
  is_followed?: boolean;
  is_follower?: boolean;
  is_first_login?: boolean;
  linked_third_parties?: string[];
  postal_code?: string;
  street?: string;
  city?: string;
  province?: string;
  country?: string;
  account_type?: string;
  badges: { url: string; reason: string }[];
  status?: string;
  tournament?: Tournament;
  tournaments?: Tournament[];
  show_profile_onboarding?: boolean;

  phone_number?: string | null;

  num_catch_logs?: number | null;
  mycatch_tokens?: number;
  stats?: {
    num_trips: number;
    num_catches: number;
    hours_out: number;
    num_waterbodies: number;
    catch_rate: number;
  };
  stats_catches?: {
    id: string;
    name: string;
    count: number;
  }[];
  source?: string;
};

export type TruncatedUser = PartialExcept<
  User,
  'id' | 'created_at' | 'username' | 'first_name' | 'last_name' | 'display_name' | 'avatar_url'
>;

export function userTypeIs(user: User | null, type: string) {
  const types = ['TOURNAMENT_ADMIN', 'FRONTEND_ADMIN', 'TRIP_VIEWER', 'ADMIN'];

  return types.indexOf(user?.account_type) >= types.indexOf(type);
}

//------------------------------------------------------------------------------

export function cloneUser<T extends User | TruncatedUser>(user: T | null | undefined): T | null | undefined {
  const result = clone(user);

  if (result && user) {
    result.linked_third_parties = cloneArray(user.linked_third_parties) ?? [];
    result.badges = cloneArray(user.badges, (it) => ({ url: it.url.slice(), reason: it.reason.slice() })) ?? [];
  }

  return result;
}

export function isTournamentAdmin(user: User | undefined | null): boolean {
  const type = user?.account_type;
  const result = type === 'ADMIN' || type === 'TOURNAMENT_ADMIN' || type === 'FRONTEND_ADMIN' || type === 'TRIP_VIEWER';
  return result;
}
