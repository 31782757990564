<div #overlayElement class="ol-popup info-window">
  <div *ngIf="selectedTripLog">
    <i class="material-icons close"
       (click)="clearSelected()">close</i>
    <div class="place-name">
      {{ selectedTripLog.place_name }}
    </div>
    <div class="last-trip">
      Last Trip: {{ selectedTripLog.last_date | toDate | date:'longDate'}}
    </div>
    <div class="stats">
      <div class="stat">
        <div class="number mycatch-color-3-text">{{ selectedTripLog.hours_out }}</div>
        <div class="label">Hours</div>
      </div>
      <div class="divider"></div>
      <div class="stat">
        <div class="number mycatch-color-2-text">{{ selectedTripLog.total_caught }}</div>
        <div class="label">Caught</div>
      </div>
      <div class="divider"></div>
      <div class="stat">
        <div class="number mycatch-color-4-text">{{ selectedTripLog.catch_rate }}</div>
        <div class="label">Per Hour</div>
      </div>
    </div>
    <a class="btn primary-btn"
        [routerLink]="selectedTripLog.routerLink"
        [queryParams]="selectedTripLog.queryParams"
    >
      {{ selectedTripLog.buttonText }}
    </a>
  </div>
</div>