<div class="relative flex flex-col items-center scrim">
  <img class="absolute left-0 right-0 bottom-0 w-full z-0 opacity-50" src="assets/facet.svg" />

  <h1 class="text-white text-center">ANGLER DATA AT YOUR FINGERTIPS</h1>
  <h3 class="text-white mt-4 text-center">Get personalized fisheries data to suit your research needs with MyCaatch Research Dashboards</h3>
  <div class="row space-x-4 mt-8">
    @if (isLoggedIn | async) {
      <a class="btn btn-filled btn-green-light" [routerLink]="['/dashboards']">View Dashboards</a>
    } @else {
      <a href="mailto:sean@anglersatlas.com?subject=MyCatch%20Dashboards" class="btn btn-filled btn-green-light">Request Discovery Meeting</a>
    }
  </div>
  <div class="z-10 mt-20 w-full max-w-4xl flex items-center justify-end">
    <img priority ngSrc="assets/landing/dashboard_preview.png" [width]="935" [height]="614" />
  </div>

  <ng-template *ngTemplateOutlet="waves1; context: { fill: '#fff' }"></ng-template>
</div>

<div class="relative py-12 bg-gradient-to-b from-white to-gray-50">
  <div class="pt-12 pb-28 flex flex-col items-center space-y-12">
    <div class="flex flex-col items-center space-y-2">
      <h2 class="text-center">WHICH DASHBOARD IS RIGHT FOR YOU?</h2>
      <h4 class="text-center">We provide different types of dashboards with customizable data to ensure that you'll find that suits your research needs</h4>
    </div>
    <div class="flex flex-wrap items-center justify-center space-x-16">
      <div class="w-60 flex flex-col items-center space-y-2">
        <img src="assets/landing/trophy.png" class="w-40 h-40" />
        <h4 class="text-center">Tournament Dashboard</h4>
        <p class="text-center text-gray-400">
          Are you a MyCatch tournament organizer? View catch rate, angling pressure and total number of fish from your tournament.
        </p>
      </div>
      <div class="w-60 flex flex-col items-center space-y-2">
        <img src="assets/landing/region.png" class="w-40 h-40" />
        <h4 class="text-center">Regional Dashboard</h4>
        <p class="text-center text-gray-400">
          Researching a particular region?<br />
          You'll be able to see aggregated data from our anglers who have fished there.
        </p>
      </div>
      <div class="w-60 flex flex-col items-center space-y-2">
        <img src="assets/landing/fish.png" class="w-40 h-40" />
        <h4 class="text-center">Population Prediction Dashboard</h4>
        <p class="text-center text-gray-400">
          Made specifically for our AFS event, this dashboard allows you to see population predictions over time based on tagged catch data.
        </p>
      </div>
      <div class="w-60 flex flex-col items-center space-y-2">
        <img src="assets/landing/chart.png" class="w-40 h-40" />
        <h4 class="text-center">Predictive Analytics Dashboard</h4>
        <p class="text-center text-gray-400">
          Use our website analytics, catch data and machine learning algorithms to predict angling pressure and other data points.
        </p>
      </div>
    </div>
  </div>
  <svg class="absolute bottom-0 left-0" width="100%" viewBox="0 0 1444 48" fill="none">
    <path
      d="M-3.5 48C-3.5 48 111.119 20 289.345 20C467.571 20 556.369 47 673.507 47C790.645 47 1026.18 -6.41215e-10 1172.92 0C1319.66 6.41215e-10 1447.5 48 1447.5 48H-3.5Z"
      fill="white"
    />
  </svg>
</div>

<div class="relative px-16 pt-16 pb-44 bg-white">
  <div class="flex flex-col items-center space-y-16">
    <div class="w-full max-w-4xl flex items-center space-x-16">
      <div class="grow flex flex-col space-y-6">
        <h3>Interactive Map Views</h3>
        <p>Quickly see the important things at a visual glance with our interactive maps that show you data points like catch rate and angling pressure</p>
      </div>
      <div class="shrink-0 w-full h-72 max-w-sm">
        <img src="assets/landing/map_view.png" class="h-full" />
      </div>
    </div>
    <div class="w-full max-w-4xl flex items-center space-x-16">
      <div class="shrink-0 w-full h-72 max-w-sm">
        <img src="assets/landing/table_view.png" class="h-full" />
      </div>
      <div class="grow flex flex-col space-y-6">
        <h3>Comprehensive Data Tables</h3>
        <p>See raw angler data directly in data tables. Choose what information is displayed.</p>
      </div>
    </div>
    <div class="w-full max-w-4xl flex items-center space-x-16">
      <div class="grow flex flex-col space-y-6">
        <h3>Powerful Prediction Analytics</h3>
        <p>Utilize our machine learning algorithms and bayesian belief networks, supplemented by angler submitted data, to predict prospective events.</p>
      </div>
      <div class="shrink-0 w-full h-72 max-w-sm">
        <img src="assets/landing/pop_view.png" class="h-full" />
      </div>
    </div>
  </div>
  <ng-template *ngTemplateOutlet="waves1; context: { fill: '#1B2033' }"></ng-template>
</div>

<div class="relative px-16 py-16 bg-gray-800 overflow-hidden">
  <img class="z-0 absolute left-0 right-0 top-0 bottom-0 w-full h-full opacity-20" src="assets/facet.svg" />
  <div class="flex flex-col items-center space-y-8">
    <div class="z-10 flex flex-col space-y-4">
      <h2 class="text-white text-center">READY TO GET STARTED?</h2>
      <!-- <h4 class="text-gray-200 text-center">Try out our live demo to see which dashboard best fits your needs!</h4> -->
    </div>
    <div class="z-10 flex space-x-4">
      <a href="mailto:sean@anglersatlas.com?subject=MyCatch%20Dashboards" class="btn btn-filled btn-green-light">Sign Up for Personalized Dashboards</a>
      <!-- <button class="btn btn-outlined btn-white">Try Live Demo</button> -->
    </div>
  </div>
</div>

<ng-template #waves1 let-fill="fill">
  <svg class="absolute bottom-0 left-0 z-20" width="100%" viewBox="0 0 1444 82" [attr.fill]="fill">
    <g filter="url(#bottom-shadow)">
      <path d="M0 34C0 34 205.569 82 448.116 82C718.653 82 791.567 34 1018.45 34C1245.32 34 1444 82 1444 82H0V34Z" />
    </g>
    <defs>
      <filter id="bottom-shadow" x="-30" y="0" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="-4" />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" result="dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="dropShadow" />
      </filter>
    </defs>
  </svg>
</ng-template>
