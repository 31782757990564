import { Component, Input, OnChanges, SimpleChanges, Inject, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Feature as OpenLayerFeature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import { EmptyMapComponent, MapLayer } from '../empty-map/empty-map.component';
import { combineLatest } from 'rxjs';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { SimpleMarker } from '@aa/models/simple-marker';
import { Style, Icon } from 'ol/style';

const DECODER = new GeoJSON({
  featureProjection: 'EPSG:3857'
});

@Component({
  selector: 'app-map-highlight-marker',
  templateUrl: './map-highlight-marker.component.html',
  styleUrls: ['./map-highlight-marker.component.scss'],
})
export class MapHighlightMarkerComponent implements AfterViewInit, OnChanges, OnDestroy {

  @Input() marker: SimpleMarker;

  @Output() onClear = new EventEmitter();

  private isReady = false;

  constructor(
    @Inject(EmptyMapComponent) private parent: EmptyMapComponent,
  ) { }

  ngAfterViewInit() {
    const layer = new VectorLayer({
      source: new VectorSource()
    });
    const deselect = () => {
      this.onClear.emit();
    }
    this.parent.addLayer({
      key: MapLayer.HighlightMarker,
      layer: layer,
      onDeselect: deselect
    });

    this.isReady = true;
    this.load();
  }

  ngOnDestroy() {
    this.parent.removeLayer(MapLayer.HighlightMarker);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isReady) {
      this.load();
    }
  }

  private load() {
    combineLatest(
      this.parent.getLayer(MapLayer.HighlightMarker),
      this.parent.getLayer(MapLayer.Markers)
    ).subscribe(layers => {
      const layer = layers[0];
      const markerLayer = layers[1];
      if (!layer || !markerLayer) { return }

      const source = layer.getSource();
      source.clear();

      layer.getSource().clear();

      if (this.marker) {
        const feature = new OpenLayerFeature({
          geometry: DECODER.readGeometry(this.marker.geometry),
          name: this.marker.type,
          marker: this.marker
        });

        const publicPrivate = this.marker.is_private ? 'private' : 'public';
        const filename = this.marker.type.toLowerCase().replace('_', '-');
        const markerIconSrc = `/assets/markers/${publicPrivate}/${filename}-highlight.svg`;

        const style = new Style({
          image: new Icon({
            size: [72, 72],
            src: markerIconSrc
          })
        });

        feature.setStyle(style);

        layer.getSource().addFeature(feature);

        markerLayer.setOpacity(0.4);
      } else {
        markerLayer.setOpacity(1);
      }
    });
  }
}
