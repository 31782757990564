<div class="z-10 grow w-full max-w-4xl mx-auto mt-20 flex flex-col">
  <div class="w-full flex items-center">
    <h3 class="grow text-white">My Dashboards</h3>
    <button class="btn btn-outlined btn-white" (click)="onClickLogout()">Logout</button>
  </div>
  <div class="grow mt-4 px-6 py-8 rounded-tl rounded-tr overflow-clip bg-gray-25 flex flex-col space-y-12">
    @if ((dashboardsLoading | async) === true || (navigating | async) === true) {
      <div class="my-4 w-full flex justify-center">
        <svg-icon name="spinner" svgClass="animate-spin size-20"></svg-icon>
      </div>
    } @else {
      <div class="flex flex-col space-y-2">
        @for (dashboard of dashboards | async; track dashboard.id) {
          <div class="bg-white border border-gray-100 rounded overflow-clip flex items-center px-4 py-3 space-x-4">
            @if (dashboard.logoUrl) {
              <img class="w-16 h-16 shrink-0 outline outline-1 outline-gray-100 rounded-full bg-white" [src]="dashboard.logoUrl" />
            }
            <div class="grow flex flex-col space-y-1 overflow-hidden">
              <h5 class="text-gray-800 truncate">{{ dashboard.name }}</h5>
              <span class="text-body-small text-gray-400">{{ dashboard.type }}</span>
            </div>
            <button type="btn" class="size-10 rounded-full flex items-center justify-center hover:bg-yellow/10" (click)="onClickToggleStarred(dashboard.id)">
              <svg-icon name="star" class="size-6" svgClass="{{ dashboard.starred ? 'fill-yellow' : 'fill-gray-100' }}" />
            </button>
            <a class="shrink-0 btn btn-outlined btn-green-light" [routerLink]="dashboard.links[0].routerLink">Open Dashboard</a>
          </div>
        }
      </div>
    }
  </div>
</div>

<img class="absolute left-0 right-0 bottom-0 w-full z-0 opacity-50" src="assets/facet.svg" />
