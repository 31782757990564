import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pageable, PageableOptions } from '@aa/helpers/pageable';
import { Page, toPage } from '@aa/models/page';
import { Place, PlaceSearchResult } from '@aa/models/place';
import { Regulation, RegulationType } from '@aa/models/regulation';
import { Bounds } from '@aa/models/bounds';
import { Coordinates } from '@aa/models/coordinates';

export interface PlaceOptions extends PageableOptions {
  term?: string;
  userCoordinates?: Coordinates;
  bounds?: Bounds;
  type?: string;
  supertype?: string;
  logicalBoundaryId?: number;
}

export function placeOptionsToHttpParams(options: PlaceOptions): HttpParams {
  const params = {};

  if (options.letter) {
    params['letter'] = options.letter;
  }

  if (options.pageSize) {
    params['per-page'] = `${options.pageSize}`;
  }
  if (options.term) {
    params['name'] = options.term;
  }
  if (options.bounds) {
    params['max_lat'] = `${options.bounds.maxLatitude}`;
    params['max_lon'] = `${options.bounds.maxLongitude}`;
    params['min_lat'] = `${options.bounds.minLatitude}`;
    params['min_lon'] = `${options.bounds.minLongitude}`;
  }
  if (options.userCoordinates) {
    params['lat'] = `${options.userCoordinates.latitude}`;
    params['lon'] = `${options.userCoordinates.longitude}`;
  }
  if (options.type) {
    params['type'] = options.type;
  }
  if (options.supertype) {
    params['supertype'] = options.supertype;
  }

  if (options.logicalBoundaryId) {
    params['logical_boundary_id'] = options.logicalBoundaryId;
  }

  return new HttpParams({ fromObject: params });
}

@Injectable({
  providedIn: 'root',
})
export class PlaceService implements Pageable<Place> {
  static urlTo(place: Place): any[] {
    return ['/place', place.id, place.slug];
  }

  static urlToPlaceSearchResult(place: PlaceSearchResult): any[] {
    return ['/place', place.id, place.slug];
  }

  static urlToPlace(id: number, slug: string) {
    return ['/place', id, slug];
  }

  constructor(private http: HttpClient) {}

  get(id: number): Observable<HttpResponse<Place>> {
    return this.http.get<Place>(`/places/${id}`, { observe: 'response' });
  }

  getPlace(id: number): Observable<Place> {
    return this.http.get<Place>(`/places/${id}`);
  }

  getGeojson(id: number): Observable<object> {
    return this.http.get<object>(`/places/${id}/geojson`);
  }

  page(page: number, options: PlaceOptions = {}): Observable<Page<Place>> {
    const params = placeOptionsToHttpParams(options).set('page', `${page}`);

    return this.http.get<Place[]>('/places', { params, observe: 'response' }).pipe(map(toPage));
  }

  follow(id: number): Observable<Place> {
    return this.http.post<Place>(`/places/${id}/followers`, null);
  }

  unfollow(id: number): Observable<Place> {
    return this.http.delete<Place>(`/places/${id}/followers`);
  }

  urlTo(place: Place, tab?: string) {
    const parts = ['/place', place.id, place.slug];
    if (tab) {
      parts.push(tab);
    }

    return parts;
  }

  urlToPlace(id: number, slug: string) {
    return ['/place', id, slug];
  }

  listTopPlaces(id: number, perPage = 5): Observable<Place[]> {
    const params = { 'per-page': `${perPage}` };
    return this.http.get<Place[]>(`/places/${id}/top`, { params });
  }

  nearest(lat: number, lng: number): Observable<Place> {
    return this.http.get<Place>(`/places/${lng}/${lat}?expand=boundary`);
  }

  filters(type: string, within: number): Observable<{ name: string; id: number }[]> {
    let params = new HttpParams({ fromObject: { type, within: `${within}` } });
    return this.http.get<{ name: string; id: number }[]>(`/places/filters`, { params });
  }

  refreshStatus(): Observable<{ last_refreshed: Date; last_requested: Date }> {
    return this.http.get<{ last_refreshed: number; last_requested: number }>(`/places/refresh`).pipe(
      map(value => {
        return {
          last_refreshed: value.last_refreshed ? new Date(value.last_refreshed * 1000) : null,
          last_requested: value.last_requested ? new Date(value.last_requested * 1000) : null
        };
      })
    );
  }

  refresh(): Observable<any> {
    return this.http.post(`/places/refresh`, {});
  }

  regulationTypes(id: number): Observable<RegulationType[]> {
    return this.http.get<RegulationType[]>(`/places/${id}/regulations`);
  }

  regulations(id: number, type: string): Observable<Regulation[]> {
    return this.http.get<Regulation[]>(`/places/${id}/regulations/${type}`);
  }
}
