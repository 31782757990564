// generated: Wed Oct 23 2024 23:06:23 GMT-0700 (Pacific Daylight Saving Time)
// command: `npm run build-tailwind-colors`
// reference file: `shared/tailwind-color-vars`

export const colors = {
  "blue-100": "#bfe2f5",
  "blue-200": "#93cfec",
  "blue-300": "#63bbe1",
  "blue-400-a16": "#28a7d329",
  "blue-400-a8": "#28a7d314",
  "blue-400": "#28a7d3",
  "blue-50": "#eaf5fc",
  "blue-500": "#0093c1",
  "blue-600-a16": "#007eac29",
  "blue-600-a8": "#007eac14",
  "blue-600": "#007eac",
  "blue-700": "#006a94",
  "blue-800": "#005579",
  "blue-900": "#00415d",
  "green-100": "#c3e5ce",
  "green-200": "#9cd3b0",
  "green-300": "#78c094",
  "green-400-a16": "#55ac7a29",
  "green-400-a8": "#55ac7a14",
  "green-400": "#55ac7a",
  "green-50": "#ebf6ee",
  "green-500": "#359763",
  "green-600-a16": "#1c875329",
  "green-600-a8": "#1c875314",
  "green-600": "#1c8753",
  "green-700": "#006d3e",
  "green-800": "#005930",
  "green-900": "#004324",
  "neutral-0-a16": "#ffffff29",
  "neutral-0-a24": "#ffffff3d",
  "neutral-0-a32": "#ffffff52",
  "neutral-0-a40": "#ffffff66",
  "neutral-0-a48": "#ffffff7a",
  "neutral-0-a64": "#ffffffa3",
  "neutral-0-a8": "#ffffff14",
  "neutral-0-a80": "#ffffffcc",
  "neutral-0": "#ffffff",
  "neutral-100": "#d8dbe5",
  "neutral-1000": "#111422",
  "neutral-200": "#b4b9cc",
  "neutral-25": "#f5f6fa",
  "neutral-300": "#9299b2",
  "neutral-400-a16": "#747c9929",
  "neutral-400-a24": "#747c993d",
  "neutral-400-a32": "#747c9952",
  "neutral-400-a40": "#747c9966",
  "neutral-400-a48": "#747c997a",
  "neutral-400-a64": "#747c99a3",
  "neutral-400-a8": "#747c9914",
  "neutral-400-a80": "#747c99cc",
  "neutral-400": "#747c99",
  "neutral-50": "#ebedf2",
  "neutral-500": "#596180",
  "neutral-600-a16": "#41496629",
  "neutral-600-a24": "#4149663d",
  "neutral-600-a32": "#41496652",
  "neutral-600-a40": "#41496666",
  "neutral-600-a48": "#4149667a",
  "neutral-600-a64": "#414966a3",
  "neutral-600-a8": "#41496614",
  "neutral-600-a80": "#414966cc",
  "neutral-600": "#414966",
  "neutral-700": "#2c334d",
  "neutral-800": "#22283f",
  "neutral-900": "#191e2f",
  "orange-100": "#ffd2a9",
  "orange-200": "#ffb575",
  "orange-300": "#fb9945",
  "orange-400-a16": "#ea7f1129",
  "orange-400-a8": "#ea7f1114",
  "orange-400": "#ea7f11",
  "orange-50": "#fff0e2",
  "orange-500": "#d56800",
  "orange-600-a16": "#bd530029",
  "orange-600-a8": "#bd530014",
  "orange-600": "#bd5300",
  "orange-700": "#a14100",
  "orange-800": "#853100",
  "orange-900": "#672500",
  "red-100": "#ffccba",
  "red-200": "#ffaa90",
  "red-300": "#ff896b",
  "red-400-a16": "#ff694b29",
  "red-400-a8": "#ff694b14",
  "red-400": "#ff694b",
  "red-50": "#ffeee7",
  "red-500": "#f04b31",
  "red-600-a16": "#d8301c29",
  "red-600-a8": "#d8301c14",
  "red-600": "#d8301c",
  "red-700": "#ba1a0e",
  "red-800": "#990c06",
  "red-900": "#760b02",
  "background-surface": "#ffffff",
  "background": "#ebedf2",
  "background-subtle": "#f5f6fa",
  "background-obvious": "#d8dbe5",
  "background-surface-inverted": "#111422",
  "background-inverted": "#22283f",
  "background-subtle-inverted": "#191e2f",
  "background-obvious-inverted": "#2c334d",
  "background-neutral-default": "#ffffff",
  "background-neutral-hover": "#f5f6fa",
  "background-neutral-hover-overlay": "#41496614",
  "background-neutral-pressed": "#ebedf2",
  "background-neutral-pressed-overlay": "#41496629",
  "background-disabled": "#d8dbe5",
  "background-accent-default": "#1c8753",
  "background-accent-subtle": "#ebf6ee",
  "background-accent-disabled": "#9cd3b0",
  "background-accent-hover": "#006d3e",
  "background-accent-hover-overlay": "#1c875314",
  "background-accent-pressed": "#005930",
  "background-accent-pressed-overlay": "#1c875329",
  "background-warning-default": "#bd5300",
  "background-warning-subtle": "#fff0e2",
  "background-warning-disabled": "#ffb575",
  "background-warning-hover": "#a14100",
  "background-warning-hover-overlay": "#bd530014",
  "background-warning-pressed": "#853100",
  "background-warning-pressed-overlay": "#bd530029",
  "background-negative-default": "#d8301c",
  "background-negative-subtle": "#ffeee7",
  "background-negative-disabled": "#ffaa90",
  "background-negative-hover": "#ba1a0e",
  "background-negative-hover-overlay": "#d8301c14",
  "background-negative-pressed": "#990c06",
  "background-negative-pressed-overlay": "#d8301c29",
  "background-blue-default": "#007eac",
  "background-blue-subtle": "#eaf5fc",
  "background-blue-disabled": "#93cfec",
  "background-blue-hover": "#006a94",
  "background-blue-hover-overlay": "#007eac14",
  "background-blue-pressed": "#005579",
  "background-blue-pressed-overlay": "#007eac29",
  "border-default": "#d8dbe5",
  "border-default-inverted": "#191e2f",
  "border-light": "#ffffff",
  "border-secondary": "#b4b9cc",
  "border-tertiary": "#9299b2",
  "border-disabled": "#d8dbe5",
  "border-neutral-default": "#ffffff",
  "border-neutral-hover": "#d8dbe5",
  "border-neutral-pressed": "#b4b9cc",
  "border-neutral-subtle-default": "#ffffff66",
  "border-neutral-subtle-hover": "#ffffffcc",
  "border-neutral-subtle-pressed": "#ffffff",
  "border-accent-default": "#1c8753",
  "border-accent-disabled": "#9cd3b0",
  "border-accent-hover": "#006d3e",
  "border-accent-hover-overlay": "#1c875314",
  "border-accent-pressed": "#005930",
  "border-accent-pressed-overlay": "#1c875329",
  "border-warning-default": "#bd5300",
  "border-warning-disabled": "#ffb575",
  "border-warning-hover": "#a14100",
  "border-warning-hover-overlay": "#bd530014",
  "border-warning-pressed": "#853100",
  "border-warning-pressed-overlay": "#bd530029",
  "border-negative-default": "#d8301c",
  "border-negative-disabled": "#ffaa90",
  "border-negative-hover": "#ba1a0e",
  "border-negative-hover-overlay": "#d8301c14",
  "border-negative-pressed": "#990c06",
  "border-negative-pressed-overlay": "#d8301c29",
  "text-icon-default": "#111422",
  "text-icon-default-inverted": "#ffffff",
  "text-icon-secondary": "#414966",
  "text-icon-tertiary": "#747c99",
  "text-icon-disabled": "#b4b9cc",
  "text-icon-neutral-subtle-default": "#ffffff66",
  "text-icon-neutral-subtle-hover": "#ffffffcc",
  "text-icon-neutral-subtle-pressed": "#ffffff",
  "text-icon-neutral-inverted-default": "#ffffff",
  "text-icon-accent-default": "#1c8753",
  "text-icon-accent-disabled": "#9cd3b0",
  "text-icon-accent-hover": "#006d3e",
  "text-icon-accent-hover-overlay": "#1c875314",
  "text-icon-accent-pressed": "#005930",
  "text-icon-accent-pressed-overlay": "#1c875329",
  "text-icon-blue-default": "#007eac",
  "text-icon-blue-disabled": "#93cfec",
  "text-icon-blue-hover": "#006a94",
  "text-icon-blue-hover-overlay": "#007eac14",
  "text-icon-blue-pressed": "#005579",
  "text-icon-blue-pressed-overlay": "#007eac29",
  "text-icon-warning-default": "#bd5300",
  "text-icon-warning-hover": "#a14100",
  "text-icon-warning-hover-overlay": "#bd530014",
  "text-icon-warning-pressed": "#853100",
  "text-icon-warning-pressed-overlay": "#bd530029",
  "text-icon-negative-default": "#d8301c",
  "text-icon-negative-hover": "#ba1a0e",
  "text-icon-negative-hover-overlay": "#d8301c14",
  "text-icon-negative-pressed": "#990c06",
  "text-icon-negative-pressed-overlay": "#d8301c29",
  "transparent": "transparent",
  "white": "#fff",
  "black": "#000",
  "purple": "#6073EB",
  "purple-faint": "#F0F1FD",
  "purple-dark": "#4B5AB7",
  "red": "#F24D33",
  "red-dark": "#D8301C",
  "red-faint": "#FFAA90",
  "red-border": "#D8301C",
  "blue": "#08B2E3",
  "blue-light": "#3acff8",
  "blue-super-light": "#89e2fb",
  "blue-dark": "#22283F",
  "blue-super-dark": "#191E2F",
  "blue-ultra-dark": "#111422",
  "blue-default": "#007EAC",
  "green": "#1C8753",
  "green-light": "#16A05D",
  "green-super-light": "#1DC975",
  "green-ultra-light": "#24FF8A",
  "green-faint": "#EDF6F2",
  "green-border": "#C6E1D4",
  "teal": "#4FC0C4",
  "yellow": "#FF8800",
  "yellow-light": "#FFF3E5",
  "gray-25": "#F5F6FA",
  "gray-50": "#EBEDF2",
  "gray-100": "#D8DBE5",
  "gray-200": "#B4B9CC",
  "gray-300": "#9299B2",
  "gray-400": "#747C99",
  "gray-500": "#596180",
  "gray-600": "#414966",
  "gray-700": "#2C334D",
  "gray-800": "#1B2033"
};