<div class="z-10 w-full max-w-md m-auto mt-20 rounded bg-white border-gray-100">
  @if (dataFor2fa) {
  <div class="p-4 flex flex-col justify-center gap-2 items-center">
    <h4 class="p-4 border-0 border-b-gray-100">
      @switch (dataFor2fa.type) {
      @case ('email') { Check your inbox }
      }
    </h4>
    Enter the 6-digit security code we sent to
    <strong class="-mt-2 mb-2">{{ dataFor2fa.hint }}</strong>
    <app-code-input #code
                    [lengthOfCode]="6"
                    [disabled]="loggingIn"
                    separator=" " />
    <button (click)="onSubmit2faCode(code.codeString)"
            [disabled]="code.codeString?.length != 6 || loggingIn"
            class="btn btn-filled btn-green-light w-full mt-4">Continue</button>
    <a (click)="dataFor2fa = null"
       class="text-center text-button w-full mt-2">Back to log in</a>
  </div>
  } @else {
  <h4 class="p-4 border-0 border-b-gray-100">Login with your Angler's Atlas account</h4>
  <form class="p-4 flex flex-col space-y-4">
    <input #email
           [attr.disabled]="loggingIn ? '' : null"
           type="email"
           placeholder="Email"
           class="h-10 px-4 bg-transparent border-gray-100 rounded placeholder:text-gray-300 active:ring-0 focus:ring-0 disabled:bg-gray-25 hover:disabled:cursor-not-allowed disabled:text-gray-300" />

    <div class="flex items-center h-10 pr-2 border border-gray-100 rounded"
         [class.bg-gray-25]="loggingIn">
      <input #password
             [attr.disabled]="loggingIn ? '' : null"
             [attr.type]="showingPassword ? 'text' : 'password'"
             placeholder="Password"
             class="w-full h-10 px-4 bg-transparent border-0 placeholder:text-gray-300 active:ring-0 focus:ring-0 disabled:bg-gray-25 hover:disabled:cursor-not-allowed disabled:text-gray-300" />
      <svg-icon [name]="showingPassword ? 'eye-close' : 'eye-open'"
                svgClass="fill-gray-300"
                class="size-6 hover:cursor-pointer"
                [attr.title]="showingPassword ? 'Hide password' : 'Show password'"
                (click)="onClickToggleShowPassword()"></svg-icon>
    </div>
    <button class="btn btn-filled btn-green-light"
            [attr.disabled]="loggingIn ? '' : null"
            (click)="onClickLogin(email.value, password.value)">Login</button>
    <div class="h-10 flex items-center justify-center">
      <a [routerLink]="['/forgot-password']"
         class="text-green-light text-button">Forgot Password?</a>
    </div>
  </form>
  <div class="mx-4 mb-4 flex flex-col gap-y-4">
    <div class="flex items-center gap-x-2">
      <div class="grow h-px bg-border-default"></div>
      <span class="shrink-0 text-text-icon-tertiary">OR</span>
      <div class="grow h-px bg-border-default"></div>
    </div>
    <app-third-party-login-buttons [thirdParties]="thirdParties"
                                   [compact]="true"
                                   (loginEvent)="onThirdPartyLoginEvent($event)" />
  </div>
  }
  @if (errorMessage) {
  <p class="mt-2 px-4 py-2 bg-red/50 border border-red/75 rounded-b">{{ errorMessage }}</p>
  }
</div>
<img class="absolute left-0 right-0 bottom-0 w-full z-0 opacity-50"
     src="assets/facet.svg" />