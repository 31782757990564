<div class="flex gap-1 items-center">
  @for (num of [].constructor(lengthOfCode); track num; let i = $index) {
  <input #codeInput
         [id]="i === 0 ? 'code' : null"
         [disabled]="disabled"
         autofocus
         type="text"
         required
         class="browser-default tw-text-field uppercase text-center max-w-10 p-0 h-12"
         (input)="handleCodeInput($event)"
         (keydown)="handleCodeKeyDown($event)"
         (keyup)="handleCodeKeyUp($event)"
         (focus)="handleCodeFocus($event)"
         (paste)="handleCodePaste($event)"
         autocomplete="one-time-code" />
  @if (i === 2 && separator) {
  <span class="font-bold text-2xl px-2">{{ separator }}</span>
  }
  }
</div>