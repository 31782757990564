export function latLngFromGeoJsonPoint(json: string): [number, number] {
  let start = json.indexOf('[') + 1;
  let end = json.indexOf(',', start);
  const lon = json.substring(start, end);

  start = end + 1;
  end = json.indexOf(']', start);
  const lat = json.substring(start, end);

  return [parseFloat(lat), parseFloat(lon)];
}

export function staticMapPathParamFromGeoJson(json: string, includeParamName: boolean = true): string {
  const envelope = JSON.parse(json);

  let coords = envelope.coordinates[0];
  if (!Array.isArray(coords)) {
    coords = [envelope.coordinates];
  }

  const parts = ['stroke:rgba(0,0,0,0)', 'width:0'];
  for (const it of coords) {
    parts.push(`${it[1]},${it[0]}`);
  }

  let param = includeParamName ? 'path=' : '';
  param += parts.join('|');

  return param;
}

