import { Component, Input, OnChanges, SimpleChanges, Inject, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Feature as OpenLayerFeature, Overlay } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import Point from 'ol/geom/Point';
import { EmptyMapComponent, MapLayer, MapOverlay } from '../empty-map/empty-map.component';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Cluster } from 'ol/source';
import { Style, Icon, Text, Fill } from 'ol/style';
import { ChangeDetectionStrategy } from '@angular/core';
import { FeatureLike } from 'ol/Feature';

const DECODER = new GeoJSON({
  featureProjection: 'EPSG:3857'
});

@Component({
  selector: 'app-map-catch-logs',
  templateUrl: './map-catch-logs.component.html',
  styleUrls: ['./map-catch-logs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapCatchLogsComponent implements AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('overlayElement') element: ElementRef;

  @Input() catchLogs: any; // geojson
  @Input() cluster = true;

  public selectedCatchLog: {
    photo: string;
    fish_name: string;
    caught_at: string;
  };

  private overlay: Overlay;
  private onClick: (feature: OpenLayerFeature) => void;
  private isReady = false;

  constructor(
    @Inject(EmptyMapComponent) private parent: EmptyMapComponent,
    private cdr: ChangeDetectorRef,
  ) { }

  ngAfterViewInit() {
    this.overlay = new Overlay({
      id: MapOverlay.CatchLogs,
      element: this.element.nativeElement,
      autoPan: true,
    });

    this.onClick = (feature: OpenLayerFeature) => {
      const onlyFeature = feature.get('features')[0];
      this.selectedCatchLog = {
        photo: onlyFeature.get('photo'),
        fish_name: onlyFeature.get('fish_name'),
        caught_at: onlyFeature.get('caught_at')
      };
      this.cdr.detectChanges();
      this.overlay.setPosition(
        (<Point>onlyFeature.getGeometry()).getCoordinates()
      );
    }

    const clusterSource = new Cluster({
      distance: this.cluster ? 80 : 0,
      source: new VectorSource()
    });

    const styleCache: { [key: number]: Style } = {};
    const layer = new VectorLayer({
      source: clusterSource,
      style: function (feature) {
        const size = feature.get('features').length;
        let style = styleCache[size];
        if (!style) {
          if (size === 1) {
            style = new Style({
              image: new Icon({
                size: [34, 34],
                src: '/assets/markers/catch-log.svg'
              })
            });
          } else {
            style = new Style({
              image: new Icon({
                size: [66, 66],
                src: '/assets/markers/catch-log-cluster.svg'
              }),
              text: new Text({
                text: size.toString(),
                font: '18px sans-serif',
                fill: new Fill({
                  color: '#fff'
                })
              })
            });
          }
          styleCache[size] = style;
        }
        return style;
      }
    });
    this.parent.addLayer({
      key: MapLayer.CatchLogs,
      layer: layer,
      onClick: this.onClick,
      deselectOnClick: true,
      onDeselect: () => {
        this.overlay.setPosition(undefined);
      }
    });
    this.parent.addOverlay(this.overlay);

    this.isReady = true;
    this.loadPlaces();
  }

  ngOnChanges(_: SimpleChanges) {
    if (this.isReady) {
      this.loadPlaces();
    }
  }

  ngOnDestroy() {
    this.parent.removeLayer(MapLayer.CatchLogs);
    this.parent.removeOverlay(MapOverlay.CatchLogs);
  }

  private loadPlaces() {
    this.parent.getLayer(MapLayer.CatchLogs).subscribe(layer => {
      const features: OpenLayerFeature[] = [];
      if (this.catchLogs) {
        const decoded = DECODER.readFeatures(this.catchLogs);
        for (const it of decoded) {
          if (it instanceof OpenLayerFeature) {
            const id = it.get('id');
            const featureId = `catch-logs-${id}`;
            it.setId(featureId);
            features.push(it);
          }
        }
      }

      const source = (<Cluster<FeatureLike>>layer.getSource()).getSource();
      source.clear();
      source.addFeatures(features);

      this.parent.fitExtent();
    });
  }

  clearSelected() {
    this.overlay.setPosition(undefined);
    this.parent.clearSelection();
  }
}
