import { NgModule, inject } from '@angular/core';
import { CanActivateFn, Router, RouterModule, Routes } from '@angular/router';
import { AuthenticationService } from '@aa/authentication/authentication.service';
import { ErrorComponent } from './error/error.component';
import { DashboardsComponent } from './landing/dashboards/dashboards.component';
import { HomeComponent } from './landing/home/home.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './landing/login/login.component';

const loggedInGuard: CanActivateFn = () => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);

  const loggedIn = authService.loggedInSnapshot;
  if (!loggedIn) {
    router.navigate(['login']);
  }

  return loggedIn;
}

// NOTE(cg): We have a LandingModule and a LandingModule because the layout of each is pretty different (toolbars/menus/footers/backgrounds)
const routes: Routes = [
  {
    path: 'dashboards/:dashboardId',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [
      loggedInGuard,
    ],
  },
  {
    path: '',
    // TODO: Can this be a lazy load?
    component: LandingComponent,
    children: [
      {
          path: 'dashboards',
          component: DashboardsComponent,
          canActivate: [
              loggedInGuard,
          ],
      },
      {
          path: 'login',
          component: LoginComponent,
      },
      {
          path: '',
          component: HomeComponent,
      },
    ],
    // loadChildren: () => import('./landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: '**',
    component: ErrorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

