import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-code-input',
  templateUrl: './code-input.component.html',
})
export class CodeInputComponent implements OnChanges {

  @ViewChildren("codeInput") codeInputs: QueryList<ElementRef>;

  @Input() lengthOfCode: number;
  @Input() separator: string;
  @Input() disabled: boolean = false;

  @Input() code: string;
  @Output() codeChange = new EventEmitter<string>();

  @Output() keyupEnter = new EventEmitter<void>();

  get codeString(): string {
    return this.buildCode();
  }

  private previousLastChar = new Map<string, string>();

  ngOnChanges(changes: SimpleChanges): void {
    if ('code' in changes) {
      this.setCode(this.code);
    }
  }

  setCode(code?: string, el?:HTMLInputElement) {
    let lastChar: string | null = null;
    if (code?.length) {
      lastChar = code.charAt(code.length - 1).toUpperCase();
    }

    let result: string = "";

    if (lastChar) {
      const charCode = lastChar.charCodeAt(0);

      const charCode0 = 48;
      const charCode9 = 57;
      const isNumber = charCode >= charCode0 && charCode <= charCode9;

      const charCodeA = 65;
      const charCodeZ = 90;
      const isAlpha = charCode >= charCodeA && charCode <= charCodeZ;

      if (isNumber || isAlpha) {
        result = lastChar;
        this.moveNext(el);
      } else if (el) {
        const previousLastChar = this.previousLastChar.get(el.id);
        result = previousLastChar;
      }
    }

    if (el) {
      this.previousLastChar.set(el.id, result);
      el.value = result;
    }
  }

  handleCodeInput(event: Event) {
    if (!(event instanceof InputEvent)) return;

    const el = event.target as HTMLInputElement;

    const data = event.data?.trim();

    this.setCode(data, el);

    this.codeChange.emit(this.buildCode());
  }

  handleCodeKeyDown(event: KeyboardEvent) {
    const element = event.target as HTMLInputElement;

    if (event.key === "Backspace") {
      if (!element.value.length) {
        this.movePrev(element);
      }
    } else if (event.key === "ArrowRight") {
      this.moveNext(element);
    } else if (event.key === "ArrowLeft") {
      this.movePrev(element);
    }
  }

  handleCodeKeyUp(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.keyupEnter.emit();
    }
  }

  private isInput(el: Element | null): el is HTMLInputElement {
    let result = false;
    if (el) {
      result = (el as HTMLInputElement).select !== undefined;
    }
    return result;
  }

  private moveNext(element?: HTMLInputElement) {
    if (!element) return;

    let next = element.nextElementSibling;
    // skip separator
    if (next?.tagName === 'SPAN') {
      next = next.nextElementSibling;
    }

    setTimeout(() => {
      if (next && this.isInput(next)) {
        next.focus();
        next.select();
      }
    });
  }

  private movePrev(element: HTMLInputElement) {
    let prev = element.previousElementSibling;
    // skip separator
    if (prev?.tagName === 'SPAN') {
      prev = prev.previousElementSibling;
    }

    setTimeout(() => {
      if (prev && this.isInput(prev)) {
        prev.focus();
        prev.select();
      }
    });
  }

  handleCodeFocus(event: FocusEvent) {
    (<any>event.target).select();
  }

  handleCodePaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('Text');

    this.pasteCode(pastedData, <any>event.target);
    this.codeChange.emit(this.buildCode());
  }

  private pasteCode(code: string, element: any) {
    const regex = new RegExp('^[A-Z0-9]$');

    for (let letter of code.split('')) {
      letter = letter.toUpperCase();
      if (regex.test(letter)) {
        element.value = letter;
      } else {
        continue;
      }
      element = element.nextElementSibling;
      if (element == null) {
        return
      }
      if (element?.tagName === 'SPAN') {
        element = element.nextElementSibling;
      }
      element?.focus();
    }
  }

  private buildCode(): string {
    return this.codeInputs
      ?.map(elm => elm.nativeElement.value)
      ?.join('') ?? "";
  }
}
