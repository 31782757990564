import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Component({
  selector: 'dash-root',
  templateUrl: './app.component.html',
  animations: [
    trigger('loadingOverlay', [
      transition(':leave', [
        animate('100ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  isLoading = false;

  private routerEventSubscription = Subscription.EMPTY;

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.routerEventSubscription = this.router.events
      .pipe(
        filter((event) => (event instanceof NavigationStart)),
        map((event: NavigationStart) => event.url.startsWith('/tournament') || event.url.startsWith('/region')),
        take(1),
      )
      .subscribe({
        next: (loading) => this.isLoading = loading,
        error: (error) => console.error(error),
      });
  }

  ngOnDestroy() {
    this.routerEventSubscription.unsubscribe();
  }

  onActivate() {
    this.isLoading = false;
  }
}

