import { HttpHeaders, HttpResponse } from '@angular/common/http';

export type Page<T> = {
  page: number;
  perPage: number;
  totalPages: number;
  totalItems: number;
  headers?: HttpHeaders;
  items: T[];
};

export const EMPTY_PAGE: Page<any> = {
  page: 0,
  perPage: 0,
  totalPages: 0,
  totalItems: 0,
  items: [],
};

export const toPage = <T>(response: HttpResponse<Array<T>>, _?: number): Page<T> => pageFromHttpResponse(response);

function pageFromHttpResponse<T>(response: HttpResponse<T[]>): Page<T> {
  const headers = response.headers;

  const page: Page<T> = {
    items: response.body,
    page: +headers.get('X-Pagination-Current-Page'),
    perPage: +headers.get('X-Pagination-Per-Page'),
    totalItems: +headers.get('X-Pagination-Total-Count'),
    totalPages: +headers.get('X-Pagination-Page-Count'),
  };

  return page;
}

