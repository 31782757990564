import { getIcon, getOffset, isPlace, Place, PlaceSearchResult } from '@aa/models/place';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Feature as OpenLayerFeature, Overlay } from 'ol';
import { FeatureLike } from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import { transform } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { EmptyMapComponent, MapLayer, MapOverlay } from '../empty-map/empty-map.component';

const DECODER = new GeoJSON({
  featureProjection: 'EPSG:3857',
});

@Component({
  selector: 'app-map-places',
  templateUrl: './map-places.component.html',
  styleUrls: ['./map-places.component.scss'],
})
export class MapPlacesComponent<T extends PlaceSearchResult | Place> implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('highlightElement') highlightElement: ElementRef;

  @Input() places: T[];
  @Input() shouldHover = true;
  @Input() useSmallStyle = false;
  @Input() shouldDeselect = false;
  @Input() smallMarkerStyle = new Style({
    image: new CircleStyle({
      radius: 4,
      stroke: new Stroke({
        color: 'black',
        width: 1,
      }),
      fill: new Fill({
        color: '#6073EB',
      }),
    }),
  });

  @Output() placeSelected = new EventEmitter<T>();
  @Output() placeHovered = new EventEmitter<T | null>();

  private placesSource: VectorSource;
  private hoverOverlay: Overlay;

  private isReady = false;

  private styleFunc = (f: FeatureLike) => (this.useSmallStyle) ? this.smallMarkerStyle : getIcon(f.get('place') as Place, false);

  constructor(@Inject(EmptyMapComponent) private parent: EmptyMapComponent) {
  }

  ngAfterViewInit() {
    this.placesSource = new VectorSource();
    this.parent.addLayer({
      key: MapLayer.Places,
      layer: new VectorLayer({
        source: this.placesSource,
        style: (f) => this.styleFunc(f),
      }),
      onClick: (f) => this.mapOnClick(f),
      onHover: (f) => this.mapOnHover(f),
      onDeselect: () => this.mapOnDeselect(),
      deselectOnClick: true,
      hoverStyle: (f) => this.styleFunc(f),
    });

    this.hoverOverlay = new Overlay({
      id: MapOverlay.Places,
      element: this.highlightElement.nativeElement,
      autoPan: true,
    });
    this.parent.addOverlay(this.hoverOverlay);

    this.isReady = true;
    this.loadPlaces();
  }

  ngOnDestroy() {
    this.parent.removeLayer(MapLayer.Places);
    this.parent.removeOverlay(MapOverlay.Places);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isReady) {
      this.loadPlaces();
    }
  }

  private mapOnClick(feature: OpenLayerFeature) {
    this.placeSelected.emit(feature.get('place'));
  }

  private mapOnHover(feature: OpenLayerFeature) {
    if (!this.shouldHover) return;

    const place = feature?.get('place');
    this.placeHovered.next(place);

    this.parent.mapContainerElement.nativeElement.style.cursor = !!feature ? 'pointer' : '';

    const overlay = this.hoverOverlay;

    if (!feature) {
      overlay.setPosition(undefined);
      return;
    }

    let offset = [0, 0];
    if (!this.useSmallStyle) {
      const small = place.supertype === 'CITY' || place.supertype === 'WATERBODY' || !isPlace(place);
      offset = getOffset(place, small);
    }

    overlay.setOffset(offset);
    overlay.setPosition((<Point>feature.getGeometry()).getCoordinates());
    overlay.getElement().getElementsByClassName('title')[0].innerHTML = place.name;
  }

  private mapOnDeselect() {
    if (!this.shouldDeselect) return;
    this.placeSelected.emit(null);
  }

  private loadPlaces() {
    const source = this.placesSource;
    if (!source) return;

    const places = this.places ?? [];
    const features = new Array<OpenLayerFeature>(places.length);

    for (let idx = 0; idx < places.length; idx += 1) {
      const place = places[idx];

      let geometry: Geometry;
      if (place.hasOwnProperty('centroid')) {
        geometry = DECODER.readGeometry((<Place>place).centroid);
      } else {
        const preview = (<PlaceSearchResult>place).preview;
        const coords = [preview.longitude, preview.latitude];
        geometry = new Point(transform(coords, 'EPSG:4326', 'EPSG:3857'));
      }

      const feature = new OpenLayerFeature({ geometry });
      feature.setId(`places-${place.id}`);
      feature.set('place', place);
      feature.set('layer-id', MapLayer.Places);
      features[idx] = feature;
    }

    source.clear();
    source.addFeatures(features);
    this.parent.fitExtent();
  }
}
