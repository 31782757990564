export const BASE_ENVIRONMENT = {
    production: false,
    apiUrl: 'http://localhost/aa3/backend/web/v1',
    apiClientId: 'AnglersAtlas',
    apiClientSecret: 'NuMebsuWewwjoWKrRkRZul3lihD6jLv7',
    thirdParty: {
        facebook: {
            id: '192213848034725',
            type: 'facebook',
            logo: '/assets/third-party-icon-facebook.png',
            title: 'Facebook',
        },
        google: {
            id: '601893531483-pv3l08g4c5pukng8krkod3cec6voi24j.apps.googleusercontent.com',
            type: 'google',
            logo: '/assets/third-party-icon-google.png',
            title: 'Google',
        },
        apple: {
            id: 'com.anglersatlas.www',
            type: 'apple_web',
            logo: '/assets/third-party-icon-apple.png',
            title: 'Apple',
        },
    },
};

export type BaseEnvironment = { [key in keyof typeof BASE_ENVIRONMENT]: typeof BASE_ENVIRONMENT[key]; };