
export function getServerErrors(error: any): string[] {
  const result: string[] = [];
  if ('error' in error) {
    const errorProperty = error.error;
    if (Array.isArray(errorProperty)) {
      for (const e of errorProperty) {
        result.push(e['message']);
      }
    } else {
      result.push(errorProperty['message']);
    }
  } else if ('message' in error) {
    result.push(error.message);
  }
  return result;
}

type FormError = {
  field: string;
  message: string
};
// [
//   {
//       "field": "length",
//       "message": "Length cannot be blank."
//   }
// ]
export function getServerFormErrors(error: any): { [field: string]: string } | null {
  if ('error' in error && Array.isArray(error.error)) {
    const errors = error.error as FormError[];
    const result: { [field: string]: string } = {};
    for (const e of errors) {
      result[e.field] = e.message;
    }
    return result;
  }
  return null;
}