import { Fish } from '@aa/models/fish';
import { Place } from '@aa/models/place';
import { TournamentCatchLog } from '@aa/models/tournament';
import { User } from '@aa/models/user';
import { HttpClient, HttpParams, HttpParamsOptions, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Dashboard } from '@aa/models/dashboard-access';

export type DashboardsResponse = Dashboard[];

export type DashboardWaterbodiesResponseTotals = {
  waterbodies?: number;
  anglers: number;
  trips: number;
  zeros: number;
  catches: number;
  hours_out: number;
  rate: number;
  ids?: { [key: string]: number }; // key: trip id, value: # of catches
};

export type DashboardWaterbodiesResponse = {
  id: number;
  name: string;
  lon: number;
  lat: number;
  anglers: number;
  trips: number;
  zeros: number;
  catches: number;
  hours_out: number;
  rate: number;
  rate_filtered: number | null;
};

export type DashboardWaterbodiesTotalsResponse = {
  totals: DashboardWaterbodiesResponseTotals;
  totals_including_invalid?: DashboardWaterbodiesResponseTotals;
  known?: DashboardWaterbodiesResponseTotals;
  unknown?: DashboardWaterbodiesResponseTotals;
  invalid?: DashboardWaterbodiesResponseTotals;
};

export type DashboardWaterbodyResponse = {
  waterbody: DashboardWaterbodyResponseWaterbody;
  trips: DashboardWaterbodyResponseTrips[];
  catches?: DashboardWaterbodyResponseCatch[];
};
export type DashboardWaterbodyResponseWaterbody = {
  id: number;
  name: string;
  boundary: string;
};
export type DashboardWaterbodyResponseTrips = {
  id: number;
  hours_out: number;
  for_date: string;
  complete: boolean;
  lat?: number;
  lon?: number;
  catches: DashboardWaterbodyResponseTripsCatches[];
};
export type DashboardWaterbodyResponseCatch = {
  id: number | string;
  lat: number;
  lon: number;
};
export type DashboardWaterbodyResponseTripsCatches = {
  species_id: string;
  species_name: string;
  catches: number;
  // TODO: lat/lons
};

export type DashboardCatchLengthResponse = {
  species_id: string;
  species_name: string;
  date_from: string;
  date_to: string;
  catches: {
    caught_at: string;
    length: number;
    catch_log_id?: number | string;
    creel_survey_id?: number | string;
    measurement_photo_url?: string;
    outlier: boolean;
    creel_survey_lat?: number;
    creel_survey_lon?: number;
    catch_log_lat?: number;
    catch_log_lon?: number;
  }[];
};

export type DashboardCatchLengthDataRangeResponse = {
  min: number;
  max: number;
  avg: number;
  count: number;
  bucket_count: number;
  bucket_width: number;
  max_y: number;
};

export type DashboardHourlyResponse = { [key in string]: DashboardHourlyDayResponse; };

export type DashboardHourlyDayResponse = {
  trips: number[];
  hours: number[];
  catches: number[];
};

export type LengthDataResponse = {
  id: number;
  title: string;
  user: User;
  date_from: string;
  date_to: string;
  csv_file_url: string;
  waterbodies: Place[];
  species: Fish[];

  // "expanded" fields
  catches?: LengthDataCatchResponse[];
};

export type LengthDataCatchResponse = {
  id: number;
  caught_at: Date | string;
  waterbody: Place;
  species: Fish;
  length: number;
  weight: number;
};

export type DashboardPopulationResponse = {
  M: number;
  chapman_variation_of_petersen: any;
  chapman_variation_of_petersen_electrofishing?: any;
  catches: {
    creel_survey_id: number;
    user_id: number;
    species_name: string;
    length: number;
    caught_at: string;
    fish_tag: string;
  }[];
};

export type DashboardPopulationDataResponse = {
  M: number;
  data: {
    creel_survey_id: number;
    hours_out: number;
    user: {
      id: number;
      first_name: string | null;
      last_name: string | null;
      avatar_url: string;
    };
    species_name: string;
    length: number | null;
    caught_at: string;
    fish_tag: string | null;
  }[];
};

export type DashboardBlotchyResponse = {
  labels: string[];
  data: DashboardBlotchyDataResponse[];
};

export type DashboardBlotchyDataResponse = {
  id: number;
  name: string;
  lat?: number;
  lon?: number;
  envelope?: [number, number, number, number];

  total: number[];
  blotchy: number[];

  ids?: number[];
};

export type BlotchyFilters = {
  filter_blotchy_rate?: number;
  filter_month_min?: string;
  filter_month_max?: string;
};

export type Params = HttpParams | { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>; };

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) { }

  dashboards(userId: number): Observable<DashboardsResponse> {
    const params: HttpParamsOptions["fromObject"] = {
      'user_id': userId,
      expand: "tournaments,logical_boundaries,dashboard_boundary_logical_boundaries",
    };
    return this.http.get<DashboardsResponse>('/dashboards', { params: new HttpParams({ fromObject: params }) });
  }

  dashboard(dashboardId: number): Observable<Dashboard> {
    const url = `/dashboards/${dashboardId}`;
    const params = new HttpParams({ fromObject: { expand: 'boundary,dashboard_boundary_logical_boundaries,dashboard_boundary_logical_boundaries.boundary' } });
    return this.http.get<Dashboard>(url, { params });
  }

  dashboardWaterbodies(dashboardId: number, params: Params): Observable<DashboardWaterbodiesResponse[]> {
    const url = `/dashboards/${dashboardId}/waterbodies`;
    return this.http.get<DashboardWaterbodiesResponse[]>(url, { params });
  }

  dashboardWaterbodiesTotals(dashboardId: number, params?: Params): Observable<DashboardWaterbodiesTotalsResponse> {
    const url = `/dashboards/${dashboardId}/waterbodies/totals`;
    return this.http.get<DashboardWaterbodiesTotalsResponse>(url, { params });
  }

  dashboardWaterbody(dashboardId: number, waterbodyId: number): Observable<DashboardWaterbodyResponse> {
    const url = `/dashboards/${dashboardId}/waterbodies/${waterbodyId}`;
    return this.http.get<DashboardWaterbodyResponse>(url);
  }

  dashboardCatchLengths(dashboardId: number, params: Params): Observable<DashboardCatchLengthResponse[]> {
    const url = `/dashboards/${dashboardId}/catch-lengths`;
    return this.http.get<DashboardCatchLengthResponse[]>(url, { params });
  }

  dashboardCatchLengthsDataRange(dashboardId: number, params: Params): Observable<DashboardCatchLengthDataRangeResponse> {
    const url = `/dashboards/${dashboardId}/catch-lengths/data-range`;
    return this.http.get<DashboardCatchLengthDataRangeResponse>(url, { params });
  }

  hourly(dashboardId: number, params: Params): Observable<DashboardHourlyResponse> {
    const url = `/dashboards/${dashboardId}/hourly`;
    return this.http.get<DashboardHourlyResponse>(url, { params });
  }

  indexLengthData(tournamentId: number): Observable<LengthDataResponse[]> {
    const url = `/dashboards/${tournamentId}/netting-datas`;
    return this.http.get<LengthDataResponse[]>(url);
  }

  createLengthData(tournamentId: number, title: string, sourceCsv: File, importCsv: string): Observable<LengthDataResponse> {
    const url = `/dashboards/${tournamentId}/netting-datas`;
    const formData = new FormData();
    formData.append('title', title);
    formData.append('source_csv', sourceCsv, sourceCsv.name);
    formData.append('import_csv', new Blob([importCsv], { type: 'text/csv' }), 'import.csv');
    return this.http.post<LengthDataResponse>(url, formData);
  }

  getLengthData(tournamentId: number, lengthDataId: number, expand: boolean = false): Observable<HttpResponse<LengthDataResponse>> {
    const url = `/dashboards/${tournamentId}/netting-datas/${lengthDataId}`;
    let params: HttpParams;
    if (expand) {
      params = new HttpParams({ fromObject: { expand: 'catches' } });
    }
    return this.http.get<LengthDataResponse>(url, { observe: 'response', params });
  }

  deleteLengthData(tournamentId: number, lengthDataId: number): Observable<HttpResponse<void>> {
    const url = `/dashboards/${tournamentId}/netting-datas/${lengthDataId}`;
    return this.http.delete<void>(url, { observe: 'response' });
  }

  dashboardWaterbodyLengthDataCatchLengths(tournamentId: number, lengthDataId: number, waterbodyId: number): Observable<DashboardCatchLengthResponse[]> {
    const url = `/dashboards/${tournamentId}/netting-datas/${lengthDataId}/catch-lengths`;
    const params = new HttpParams({ fromObject: { waterbody_id: waterbodyId } });
    return this.http.get<DashboardCatchLengthResponse[]>(url, { params });
  }

  dashboardPopulation(tournamentId: number): Observable<DashboardPopulationResponse> {
    const url = `/dashboards/${tournamentId}/population`;
    return this.http.get<DashboardPopulationResponse>(url);
  }

  dashboardPopulationData(tournamentId: number): Observable<DashboardPopulationDataResponse> {
    const url = `/dashboards/${tournamentId}/population-data`;
    return this.http.get<DashboardPopulationDataResponse>(url);
  }

  dashboardBlotchy(dashboardId: number, filters: BlotchyFilters): Observable<DashboardBlotchyResponse> {
    const url = `/dashboards/${dashboardId}/blotchy`;
    return this.http.get<DashboardBlotchyResponse>(url, { params: new HttpParams({ fromObject: filters }) });
  }

  dashboardBlotchyLogicalBoundary(dashboardId: number, logicalBoundaryId: number, filters: BlotchyFilters): Observable<DashboardBlotchyResponse> {
    const url = `/dashboards/${dashboardId}/blotchy/${logicalBoundaryId}`;
    return this.http.get<DashboardBlotchyResponse>(url, { params: new HttpParams({ fromObject: filters }) });
  }

  dashboardBlotchyCatchesWaterbody(dashboardId: number, waterbodyId: number, filters: BlotchyFilters): Observable<TournamentCatchLog[]> {
    const url = `/dashboards/${dashboardId}/blotchy/catches/${waterbodyId}`;
    return this.http.get<TournamentCatchLog[]>(url, { params: new HttpParams({ fromObject: filters }) });
  }
}
