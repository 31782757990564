import { AuthenticationService } from '@aa/authentication/authentication.service';
import { AsyncPipe, CommonModule, NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    standalone: true,
    selector: 'dash-home',
    templateUrl: './home.component.html',
    imports: [
        CommonModule,
        AsyncPipe,
        RouterLink,
        NgOptimizedImage,
    ],
})
export class HomeComponent {
    isLoggedIn = this.authService.isLoggedIn$;
    constructor(private authService: AuthenticationService) {
    }
}
