import { AuthenticationService, AuthResponse2fa } from '@aa/authentication/authentication.service';
import { CodeInputComponent } from '@aa/components/code-input/code-input.component';
import { ThirdParty, ThirdPartyLoginButtonsComponent, ThirdPartyLoginEvent } from '@aa/components/third-party-login/third-party-login-buttons.component';
import { getServerErrors } from '@aa/helpers/network';
import { UserService } from '@aa/services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { environment } from '@environments/environment';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  standalone: true,
  imports: [
    SvgIconComponent,
    ThirdPartyLoginButtonsComponent,
    RouterLink,
    CodeInputComponent,
  ],
  selector: 'dash-landing-login',
  templateUrl: './login.component.html',
  host: {
    class: 'relative grow w-full face-gradient flex flex-col',
  }
})
export class LoginComponent {
  loggingIn = false;
  errorMessage: string | null = null;
  showingPassword = false;

  thirdParties: ThirdParty[] = Object.values(environment.thirdParty);

  private router = inject(Router);
  private authService = inject(AuthenticationService);
  private userService = inject(UserService);

  codeFor2fa: string = '';
  dataFor2fa: AuthResponse2fa | null = null;

  onClickLogin(email: string, password: string) {
    if (this.loggingIn) return;
    this.loggingIn = true;
    this.errorMessage = null;

    this.authService
      .login(email, password)
      .subscribe({
        next: (response) => {
          if (response.status === 202 && ('session_code' in response.body)) {
            this.dataFor2fa = response.body;
          } else if (response.ok) {
            this.completeLogin();
          } else {
            const errors = getServerErrors(response);
            const error = errors.length ? errors[0] : 'Failed to log in';
            this.errorMessage = error;
          }

          this.loggingIn = false;
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
          if (error?.error && 'message' in error.error) {
            this.errorMessage = error.error.message;
          } else if ('message' in error) {
            this.errorMessage = error.message;
          } else {
            this.errorMessage = 'Failed to login';
          }

          this.loggingIn = false;
        },
      });
  }

  onClickToggleShowPassword() {
    this.showingPassword = !this.showingPassword;
  }

  onThirdPartyLoginEvent(event: ThirdPartyLoginEvent) {
    if (this.loggingIn) return;
    this.loggingIn = true;
    this.errorMessage = null;

    if (event.kind == 'failure') {
      this.errorMessage = 'Failed to log in';
    } else if (event.kind == '2fa') {
      this.loggingIn = false;
      this.dataFor2fa = event.dataFor2fa;
    } else if (event.kind == 'success') {
      this.completeLogin();
    } else {
      this.errorMessage = `Failed to login (${event.type})`;
    }
  }

  onSubmit2faCode(code: string) {
    const sessionCode = this.dataFor2fa?.session_code;
    if (!sessionCode || !code) return;
    if (this.loggingIn) return;
    this.errorMessage = null;
    this.loggingIn = true;

    this.authService.verify2fa(code, sessionCode).subscribe({
      next: (response) => {
        if (response.ok) {
          this.completeLogin();
        } else if (response.status === 401 && response.statusText) {
          const error = response.statusText;
          this.errorMessage = error;
        }

        this.loggingIn = false;
      },
      error: (error) => {
        console.error(error);

        const errors = getServerErrors(error);
        const message = errors.length ? errors[0] : 'Failed to login';
        this.errorMessage = message;
        this.loggingIn = false;
      },
    });
  }

  private completeLogin() {
    this.userService.self()
      .subscribe({
        next: (user) => {
          this.authService.setUser(user);
          if (user) this.router.navigate(['/dashboards']);
        },
        error: (error) => {
          console.error(error);
          this.errorMessage = `Failed to log in with ${event.type}`;
          this.loggingIn = false;
        }
      });
  }
}
